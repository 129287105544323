.dates-container {
  max-width: 800px;
  margin: 0 auto;

  font-family: Arial, sans-serif;
}

.month-header {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  padding: 10px;
  font-size: 1.5em;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  z-index: 1;
}

.date-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.date-image-box {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-number {
  font-size: 1.5em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: bold;
}

.date-details {
  margin-left: 20px;
  flex-grow: 1;
}

.date-details h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.date-details p {
  margin: 5px 0;
  font-size: 1em;
  color: #555;
}

.date-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
