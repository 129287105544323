.box-dates-container {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.box-month-header {
  position: sticky;
  top: 0;
  font-weight: bold;
  padding: 10px;
  font-size: 44px;
  color: #333;
  z-index: 1;
}

.box-dates-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.box-date-box {
  width: 300px;
  max-height: 460px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;
}

.box-date-image-box {
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-date-number {
  font-size: 1.5em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

.box-date-details {
  flex-grow: 1;
  padding: 10px;
}

.box-date-details h3 {
  margin: 5px 0;
  font-size: 1em;
  color: #333;
}

.box-date-details p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #555;
}

.box-date-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
