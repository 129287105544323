.event-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;

  border-radius: 8px;
}

.event-title {
  text-align: center;
  font-size: 2em;
  color: #333;
}

.event-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.event-main-image {
  max-width: 100%;
  border-radius: 8px;
  height: 400px;
  width: auto;
}

.event-details {
  margin-bottom: 20px;
}

.event-details p {
  margin: 5px 0;
  font-size: 1em;
  color: #555;
}

.event-details a {
  color: #1e90ff;
  text-decoration: none;
}

.event-details a:hover {
  text-decoration: underline;
}

.event-media {
  margin-bottom: 20px;
}

.event-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.event-photos img {
  width: calc(33.333% - 10px);
  border-radius: 8px;
}

.event-video,
.event-audio {
  width: 100%;
  margin-bottom: 20px;
}

.event-game {
  margin-bottom: 20px;
}

.event-game h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.event-game p {
  margin: 5px 0;
}

.event-schedule {
  margin-bottom: 20px;
}

.event-schedule p {
  margin: 5px 0;
}
